import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectDirectory } from '@spotlight/core/stores/directory/directory.selectors';
import { first, map, switchMap } from 'rxjs';

export const profilePageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const recipientFeatureFlag = inject(AccredibleRecipientFeatureFlagsService);
  const directoryStore = inject(Store<DirectoryState>);
  const router = inject(Router);

  // Extract ID from the URL
  const urlParts = state.url.split('/');
  const rawId = urlParts[urlParts.length - 1];
  const id = rawId.split('?')[0];

  return directoryStore.select(selectDirectory).pipe(
    first((directory) => !!directory),
    switchMap((directory) => recipientFeatureFlag.getFeatureFlags(directory.id)),
    map((featureFlags) => {
      const isRedesignEnabled = featureFlags.spotlight_profile_redesign;

      if (!isRedesignEnabled) {
        return true;
      } else {
        return router.createUrlTree(['/profile-page', id]);
      }
    }),
  );
};
