import { AccredibleAPIMockData, AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpotlightApiService extends AccredibleApiService {
  protected _handleError(
    res: HttpErrorResponse,
    propagate404 = false,
    mockData?: AccredibleAPIMockData,
  ): Observable<any> {
    switch (res.status) {
      case 400:
        return super._handleError(res, propagate404, mockData);
      case 404:
        return super._handleError(res, propagate404, mockData);
      default:
        return throwError(() => res.error);
    }
  }
}
