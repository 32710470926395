import { AccredibleIcon } from '@accredible-frontend-v2/models';
import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'accredible-chip',
  templateUrl: './chip.component.html',
  styleUrls: [`./chip.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent implements OnInit {
  img = input<string>(null);
  @Input()
  data: KeyValue<number, string> | string;
  @Input()
  icon: AccredibleIcon;
  @Input()
  chipTooltip: string;
  @Input()
  role: string;
  @Input()
  removable = false;
  @Input()
  ariaLabel: string;
  @Input()
  ariaLabelRemove: string;
  @Input()
  tabIndexRemove = 0;

  @Output()
  remove = new EventEmitter<string | number>();

  private readonly _defaultIconSize = 16;

  @HostBinding('class')
  get classes(): string {
    return !this.data && !this.icon ? 'ghost' : '';
  }

  get value(): string {
    if (typeof this.data === 'string') {
      return this.data;
    }
    return this.data.value;
  }

  ngOnInit(): void {
    if (this.icon) {
      this.icon.size = this._defaultIconSize;
    }
  }

  onRemove(): void {
    this.remove.emit((<KeyValue<number, string>>this.data)?.key || <string>this.data);
  }
}
