import { Directive, Input, TemplateRef } from '@angular/core';

// Supported directions of the tooltip
export enum AccredibleTooltipDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
}

export const tooltipCommonInputs = [
  'tooltipContent',
  'tooltipDirection',
  'tooltipMaxWidth',
  'tooltipEscapeHTML',
  'tooltipCustomClass',
  'richTooltipTitle',
  'richTooltipHref',
  'richTooltipEmbeddingRoute',
  'richTooltipEmbeddingRouteParams',
  'richTooltipEmbeddingRouterLink',
  'richTooltipEmbeddingHrefUrl',
  'richTooltipLinkText',
  'customTemplate',
];

@Directive()
export class AccredibleTooltipBase {
  @Input()
  tooltipContent = '';
  @Input()
  tooltipDirection: AccredibleTooltipDirection = AccredibleTooltipDirection.TOP;
  @Input()
  tooltipMaxWidth = 300;
  @Input()
  tooltipEscapeHTML = false;
  @Input()
  tooltipCustomClass = '';
  // richTooltipTitle defines if it's to use the rich tooltip UI
  @Input()
  richTooltipTitle: string;
  @Input()
  richTooltipHref: string;
  // TODO V1(Fred): Change embedding directive logic with routerLink once we get rid of V1
  @Input()
  richTooltipEmbeddingRoute: string;
  @Input()
  richTooltipEmbeddingRouteParams: unknown;
  @Input()
  richTooltipEmbeddingRouterLink: string;
  @Input()
  richTooltipEmbeddingHrefUrl: string;
  @Input()
  richTooltipLinkText: string;
  @Input()
  customTemplate: TemplateRef<any>;
}
