import { AccredibleChipComponentModule } from '@accredible-frontend-v2/components/chip';
import { AccredibleIcon, AccredibleIconType } from '@accredible-frontend-v2/models';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'sl-credentials-skills',
  standalone: true,
  imports: [CommonModule, AccredibleChipComponentModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sl-credentials-skills.component.html',
  styleUrl: './sl-credentials-skills.component.scss',
})
export class CredentialsSkillsComponent {
  skills = input<string[]>([]);
  limitVisibleSkills = signal(false);

  readonly skillsLimit = 10;

  chevronUpIcon: AccredibleIcon = {
    type: AccredibleIconType.NEW_ACC,
    size: 16,
    name: 'chevron-up',
  };
}
