import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleBaseThemeHelper } from '@accredible-frontend-v2/utils/base-theme-helper';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';

export const spotlightAliases = ['localhost', '127.0.0.1'];

export class ThemeHelper extends AccredibleBaseThemeHelper {
  static getTheme(themeOverride?: string): string {
    if (themeOverride) {
      const themeExists = !!accredibleCustomThemesMetadata[themeOverride];

      if (environment.theming) {
        // If we are on theme preview we don't want to check the CustomThemesMetadata file
        return themeExists ? themeOverride : 'default.spotlight.theme';
      }

      return themeExists ? themeOverride : 'employment.accredible.com';
    } else {
      const appDomain = window.location.hostname;
      const envThemeExists = !!accredibleCustomThemesMetadata[environment.theme];

      return envThemeExists &&
        (spotlightAliases.includes(appDomain) ||
          WindowHelper.isLocalhost() ||
          appDomain.startsWith('192.'))
        ? environment.theme
        : !!accredibleCustomThemesMetadata[appDomain]
        ? appDomain
        : 'employment.accredible.com';
    }
  }
}
