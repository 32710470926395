<div *transloco="let t; read: 'sl-profile'"
  class="content">

  @if (profile$ | async; as profile) {
    <div class="top-actions">
      <a mat-stroked-button
        class="all-profiles-button"
        data-cy="link-all-profiles"
        [routerLink]="['/']"
        [queryParams]="queryParams">
        <div class="button-wrapper">
          <accredible-icon [icon]="chevronLeftIcon"/>
          <span>{{ t('all_profiles') }}</span>
        </div>
      </a>

      @if (!(directory$ | async)?.private) {
        <div class="copy-link-button">
          <button class="btn-copy"
            data-cy="btn-copy-link"
            mat-stroked-button
            [cdkCopyToClipboard]="copyLinkUrl()"
            (cdkCopyToClipboardCopied)="copyLink()">
            <div class="button-wrapper">
              <span>{{ isCopied() ? t('copied') : t('copy_link') }}</span>
              <accredible-icon [icon]="isCopied() ? copiedIcon : copyIcon"/>
            </div>
          </button>

        </div>
      }

    </div>
    <div class="columns-container">
      <div class="left-side"
        [class.ghost]="!profile.id">
        <sl-user-details [profile]="profile"
          [directory]="directory$ | async"
          (trackEvent)="trackEvent($event)"/>
      </div>

      <div class="right-side"
        [class.ghost]="!profile.id">

        @if (profile.directory_credentials.length || profile.non_directory_credentials.length) {
          <sl-certifications-list [profile]="profile"
            [isLoading]="!profile.id"/>
        }

        @if (profile.user_work_experiences?.length) {
          <sl-work-experience [profile]="profile"/>
        }
      </div>
    </div>
  }
</div>
