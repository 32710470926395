import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectDirectoryState } from '@spotlight/core/stores/directory/directory.selectors';
import { map } from 'rxjs';

export const forbiddenGuard: CanActivateFn = () => {
  const directoryStore = inject(Store<DirectoryState>);
  const router = inject(Router);

  return directoryStore.select(selectDirectoryState).pipe(
    map((state) => {
      if (state.loadingForbidden) {
        return true;
      } else {
        return router.createUrlTree(['/']);
      }
    }),
  );
};
