<ng-container *transloco="let t; read: 'sl-user-details'">

  <div class="no-hover tile"
    [class.ghost]="!profile().id">

    <div class="user-details-top">
      <accredible-avatar [shape]="'circle'"
        [name]="profile().name"
        [withBorder]="false"
        [isLoading]="!profile().id"
        [imageUrl]="profile().avatar_url"
        [size]="'120px'"/>

      <div class="user-info">
        <h1 class="user-name">
          {{ profile().name }}
        </h1>

        <div class="user-address-date">
          @if (!profile().id || profile().location?.name) {
            <div class="location">
              <accredible-icon icon="location"/>
              {{ profile().location.name }}
            </div>
          }

          @if (!profile().id || profile().updated_at) {
            <div class="updated-at">
              <accredible-icon icon="clock"/>
              {{ profile().updated_at | localeDate:'mediumDate': (languageCode$ | async) }}
            </div>
          }

        </div>
      </div>
    </div>
    <div class="social-media-container">
      @if (!profile().id || profile().social_media) {
        <sl-social-links
          [name]="profile().name"
          [socialLinks]="profile().social_media"
          [showAdditionalLinks]="directory()?.display_user_additional_social_links"
          (socialNetworkClicked)="trackEvent.emit($event)"/>
      }
    </div>
    <div class="user-details-bottom"
      [class.ghost]="!profile().id">
      <div class="user-description">
        @if (profile()?.id) {
          <div #profileBio
            [contentFade]="userBioExpanded() ? 'none' : bioCollapseBreakpoint">
            {{ profile().bio }}
          </div>
        }

        @if (showBioExpandButton()) {
          <div class="user-description-expand-button">
            <button mat-button
              class="read-more-button"
              (click)="toggleBio()">
              <div class="trailing-margin read-more">
                {{ t(userBioExpanded() ? 'read_less' : 'read_more') }}
                <accredible-icon [icon]="userBioExpanded() ? 'chevron-up' : 'chevron-down'"/>
              </div>
            </button>
          </div>
        }
      </div>

      <div class="skills-and-more"
        [class.show]="showSkillsAndMore()">

        @if (profile().availability?.length > 0) {
          <div class="user-work-availability">
            <div>{{ t('work_availability') }}</div>
            <accredible-chip-list [chips]="profile().availability"/>
          </div>
        }

        @if (profile().custom_attributes_values?.length > 0) {
          <div class="user-custom-attributes">
            <h2 class="additional-information">{{ t('additional_information') }}</h2>
            <sl-user-custom-attributes [customProfileAttributes]="profile().custom_attributes_values"/>
          </div>
        }

        @if (credentialsSkills().length > 0) {
          <div class="divider"></div>
          <sl-credentials-skills [skills]="credentialsSkills()"/>
        }
      </div>

      @if (profile().availability?.length > 0 || profile().custom_attributes_values?.length > 0 ||
      credentialsSkills().length > 0) {
        <button mat-button
          data-cy="btn-show-skills-and-more"
          class="show-skills-and-more-button"
          (click)="toggleSkills()">
          <div class="trailing-margin show-skills-and-more">
            {{ t(showSkillsAndMore() ? 'show_less' : 'show_skills_and_more') }}
            <accredible-icon [icon]="showSkillsAndMore() ? 'chevron-up' : 'chevron-down'"/>
          </div>
        </button>
      }

    </div>
  </div>
</ng-container>
