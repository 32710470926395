<ng-container [ngSwitch]="icon.type">

  <mat-icon *ngSwitchCase="iconType.MAT"
    [class.mat-icon-rtl-mirror]="icon.rtlMirror"
    [fontSet]="icon.fontSet"
    [style.width]="icon.size + 'px'"
    [style.height]="icon.size + 'px'"
    [style.font-size]="icon.size + 'px'">
    {{ icon.name }}
  </mat-icon>

  <mat-icon *ngSwitchCase="iconType.ACC"
    [class]="icon.name"
    [class.mat-icon-rtl-mirror]="icon.rtlMirror"
    [style.width]="icon.size + 'px'"
    [style.height]="icon.size + 'px'"
    [style.font-size]="icon.size + 'px'">
  </mat-icon>

  <span *ngSwitchCase="iconType.NEW_ACC"
    class="icon-{{icon.name}}"
    [class.support-rtl]="icon.rtlMirror">
  </span>

  <mat-icon *ngSwitchCase="iconType.SVG"
    [class.mat-icon-rtl-mirror]="icon.rtlMirror"
    [svgIcon]="icon.name"
    [style.width]="icon.size + 'px'"
    [style.height]="icon.size + 'px'"
    [style.font-size]="icon.size + 'px'">
  </mat-icon>

</ng-container>
