import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { PrivateHeaderComponent } from '@spotlight/ui-common';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectPrivateDirectoryName } from '@spotlight/core/stores/directory/directory.selectors';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoDirective, PrivateHeaderComponent],
  selector: 'spotlight-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  private readonly _directoryStore = inject(Store<DirectoryState>);

  directoryName$ = this._directoryStore.select(selectPrivateDirectoryName);
}
