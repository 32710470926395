import { AccredibleIcon, AccredibleIconType } from '@accredible-frontend-v2/models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-icon',
  templateUrl: './icon.component.html',
  styleUrls: [`./icon.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  icon: AccredibleIcon;

  iconType = AccredibleIconType;
}
