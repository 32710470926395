import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightProfileDetailed } from '@spotlight/core/models/spotlight-profiles.model';

@Component({
  standalone: true,
  imports: [CommonModule, AccredibleLocaleDatePipeModule, TranslocoModule, MatIconModule],
  templateUrl: './work-experience.component.html',
  styleUrls: [`./work-experience.component.scss`],
  selector: 'sl-work-experience',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkExperienceComponent {
  private readonly _language = inject(AccredibleLanguageService);
  profile = input<SpotlightProfileDetailed>();

  readonly languageCode$ = this._language.languageCode$;
}
