import { AccredibleIconComponentModule } from '@accredible-frontend-v2/components/icon';

import { AccredibleIcon, AccredibleIconType } from '@accredible-frontend-v2/models';
import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { AccredibleBaseContainer } from '@accredible-frontend-v2/utils/base-container';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  ActivatedRoute,
  Event,
  NavigationEnd,
  Router,
  RouterEvent,
  RouterLink,
} from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectDirectory } from '@spotlight/core/stores/directory/directory.selectors';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightEventTrackingService } from '@spotlight/shared/services/event-tracking/spotlight-event-tracking.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ThemeHelper } from '@spotlight/theme/theme.helper';
import { filter, first, take } from 'rxjs/operators';
import { CertificationsListComponent } from './components/certifications-list/certifications-list.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { WorkExperienceComponent } from './components/work-experience/work-experience.component';
import { ProfileStateAction, ProfileStore } from './store/profile.store';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AccredibleIconComponentModule,
    CertificationsListComponent,
    MatLegacyButtonModule,
    TranslocoDirective,
    UserDetailsComponent,
    WorkExperienceComponent,
    RouterLink,
    MatIconModule,
    ClipboardModule,
  ],
  providers: [ProfileStore],
  selector: 'sl-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends AccredibleBaseContainer implements OnInit {
  private readonly _directoryStore = inject(Store<DirectoryState>);
  private readonly _profileStore = inject(ProfileStore);
  private readonly _eventTrackingService = inject(SpotlightEventTrackingService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _document = inject(DOCUMENT);
  private readonly _destroyRef = inject(DestroyRef);
  protected readonly _seo = inject(AccredibleSeoService);

  readonly directory$ = this._directoryStore.select(selectDirectory);
  readonly profile$ = this._profileStore.profile$;
  readonly queryParams = this._route.snapshot.queryParams;

  copyLinkUrl = signal('');
  isCopied = signal(false);

  chevronLeftIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'icon-chevron-left',
    size: 14,
  };

  copyIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'icon-copy',
    size: 14,
  };

  copiedIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'icon-check',
    size: 14,
  };

  ngOnInit(): void {
    this.copyLinkUrl.set(window.location.href);
    this._scrollPositionHandler();

    this.directory$.pipe(first((directory) => !!directory?.id)).subscribe((directory) => {
      this._profileStore.loadProfile({
        directoryId: directory.id,
        id: this._route.snapshot.params.id,
      });

      this._updateMetaTags();
    });
  }

  trackEvent(event: string): void {
    this.directory$.pipe(first((directory) => !!directory?.id)).subscribe((directory) => {
      this._eventTrackingService.trackEvent(directory.id, 'profile', event).subscribe();
    });
  }

  copyLink(): void {
    if (this.isCopied()) {
      return;
    }

    this.isCopied.set(false);

    this.directory$.pipe(take(1)).subscribe((directory) => {
      this.isCopied.set(true);
      setTimeout(() => this.isCopied.set(false), 2000);
      this._profileStore.trackCopyProfileLink({
        directoryId: directory.id,
        userId: this._route.snapshot.params.id,
      });
    });
  }

  protected _updateMetaTags(): void {
    const profileState$ = this._profileStore.state$;

    profileState$
      .pipe(first((profileState) => profileState.action === ProfileStateAction.PROFILE_LOADED))
      .subscribe((profileState) => {
        const profile = profileState.profile;

        if (profile.name) {
          const defaultTitle = `${profile.name} • ${
            accredibleCustomThemesMetadata[ThemeHelper.getTheme()].title
          }`;

          this._seo.generateTags({
            title: defaultTitle,
            url: `/profile/${profile.id}`,
          });
        }
      });
  }

  private _scrollPositionHandler(): void {
    // The [scrollPositionRestoration] enabled on the routing configuration doesn't work
    // because scrolling event doesn't happen on the window but rather on the material sidenav component
    // In order to navigate to the top of the page and not to the middle of the screen causing a weird behaviour
    // we need to do the scrollTo on the material component
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((routerEvent: Event | RouterEvent) => routerEvent instanceof NavigationEnd),
      )
      .subscribe(() =>
        this._document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0),
      );
  }
}
