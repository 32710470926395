import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AvatarComponent } from './avatar.component';

const MAT_MODULES = [MatIconModule];

@NgModule({
  imports: [CommonModule, LazyLoadImageModule, ...MAT_MODULES],
  exports: [AvatarComponent],
  declarations: [AvatarComponent],
  providers: [],
})
export class AccredibleAvatarComponentModule {}
