import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[contentFade]',
})
/**
 * Set a transparent mask image to add a "fade" effect for the attached element
 *
 * This directive will set the attached element's max-height to the height passed in via the contentFade @input (default value: 100)
 *
 * The directive will only apply the fade if the height of the content of the attached element exceeds the max height for the element.
 *
 * The applyFadeIfNeeded method is run whenever the window is resized to ensure fade styling is accurate.
 */
export class AccredibleContentFadeDirective implements AfterViewInit {
  @Output()
  userFocused = new EventEmitter<boolean>();

  startState = '';
  endState = '';

  private _element: HTMLElement;
  private _animationDurationSeconds: number;

  constructor(private readonly _el: ElementRef, private _renderer: Renderer2) {}

  private _contentFade: 'none' | number = 100;

  @Input()
  set contentFade(value: 'none' | number) {
    this._contentFade = value;
    this._renderer.setStyle(
      this._el.nativeElement,
      'max-height',
      this._contentFade === 'none'
        ? `${this._el.nativeElement.scrollHeight}px`
        : `${this._contentFade}px`,
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    this._applyFadeIfNeeded();
  }

  // Track the transitionstart and if the element is contracting add the fade
  @HostListener('transitionstart')
  onTransitionStart(): void {
    const newStartState = this._contentFade === 'none' ? 'expanding' : 'contracting';
    if (newStartState !== this.startState) {
      this.startState = newStartState;
      // Transition start
      if (this.startState === 'contracting') {
        this._applyFadeIfNeeded(true);
      }
    }
  }

  // Track the transitionend and if the element is expanding add the fade
  @HostListener('transitionend')
  onTransitionEnd(): void {
    const newEndState =
      this._element.offsetHeight === this._contentFade ? 'contracting' : 'expanding';
    if (newEndState !== this.endState) {
      this.endState = newEndState;
      // Transition end
      if (this.endState === 'expanding') {
        this._applyFadeIfNeeded(true);
      }
    }
  }

  // We track the contentFadeFocusWithin animation, which is defined in the accessibility.scss file and is only used for elements with the contentFade attribute
  @HostListener('animationstart', ['$event.animationName'])
  onAnimationStart(animationName: string): void {
    // To be sure it's our correct event, we check for the animationName
    if (animationName === 'contentFadeFocusWithin') {
      // We add a timeout delay, allowing the animation to complete
      this._onUserFocus();
    }
  }

  ngAfterViewInit(): void {
    // We need to requestAnimationFrame in order to wait for the element to be drawn
    requestAnimationFrame(() => {
      this._element = this._el.nativeElement;
      // We roughly want to animate 1 second for every 4000px of scroll height
      this._animationDurationSeconds = this._el.nativeElement.scrollHeight / 4000;
      this._applyFadeIfNeeded();

      // Add the el-with-content-fade class to access the styling for focus-within in the accessibility.scss
      this._renderer.addClass(this._element, 'el-with-content-fade');

      // This adds a smooth transition upon expansion/collapse
      this._renderer.setStyle(
        this._element,
        'transition',
        `max-height ${this._animationDurationSeconds}s cubic-bezier(.9, .1, .83, .67`,
      );
    });
  }

  private _applyFadeIfNeeded(fromTransition = false): void {
    const maskImage = 'linear-gradient(to top, transparent 8%, black 56%)';
    const applyFade =
      fromTransition || this._el.nativeElement.offsetHeight < this._el.nativeElement.scrollHeight;

    if (applyFade && this._contentFade !== 'none') {
      this._renderer.setStyle(this._el.nativeElement, '-webkit-mask-image', maskImage);
      this._renderer.setStyle(this._el.nativeElement, 'mask-image', maskImage);
      this._renderer.setStyle(this._el.nativeElement, 'overflow', 'hidden');
    } else {
      this._renderer.removeStyle(this._el.nativeElement, '-webkit-mask-image');
      this._renderer.removeStyle(this._el.nativeElement, 'mask-image');
      this._renderer.removeStyle(this._el.nativeElement, 'overflow');
    }
  }

  private _onUserFocus(): void {
    this.userFocused.emit(true);
  }
}
