import { AccredibleKey } from '@accredible-frontend-v2/utils/key-enum';
import { Direction, Directionality } from '@angular/cdk/bidi';
import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  AccredibleTooltipBase,
  AccredibleTooltipDirection,
  tooltipCommonInputs,
} from './tooltip-base';
import { AccredibleTooltipComponent } from './tooltip.component';

let nextUniqueId = 0;

@Directive({
  selector: '[accredibleTooltip]',
})
export class AccredibleTooltipDirective extends AccredibleTooltipBase implements OnDestroy {
  @Input('accredibleTooltip')
  override tooltipContent = '';
  @Input()
  tooltipOnlyOnTruncation = false;
  @HostBinding('attr.aria-describedby')
  @Input()
  describedBy = `tooltip-${nextUniqueId++}`;

  @Output()
  tooltipOpen = new EventEmitter<boolean>();

  // Allows attaching dynamically the TooltipComponent
  private _overlayRef: OverlayRef | undefined;
  private _mouseleaveHandlerOverlay: () => void | null = null;

  // Map key value to the ConnectedPosition based on the position
  private _tooltipPositions = new Map<string, Partial<ConnectedPosition>>([
    [
      AccredibleTooltipDirection.TOP,
      getConnectedPositionObject(
        'center',
        'top',
        'center',
        'bottom',
        0,
        -10,
        'accredible-tooltip-top',
      ),
    ],
    [
      AccredibleTooltipDirection.BOTTOM,
      getConnectedPositionObject(
        'center',
        'bottom',
        'center',
        'top',
        0,
        10,
        'accredible-tooltip-bottom',
      ),
    ],
    [
      AccredibleTooltipDirection.LEFT,
      getConnectedPositionObject(
        'start',
        'center',
        'end',
        'center',
        -10,
        0,
        'accredible-tooltip-left',
      ),
    ],
    [
      AccredibleTooltipDirection.RIGHT,
      getConnectedPositionObject(
        'end',
        'center',
        'start',
        'center',
        10,
        0,
        'accredible-tooltip-right',
      ),
    ],
    [
      AccredibleTooltipDirection.TOP_LEFT,
      getConnectedPositionObject(
        'start',
        'top',
        'start',
        'bottom',
        0,
        -10,
        'accredible-tooltip-top-left',
      ),
    ],
    [
      AccredibleTooltipDirection.TOP_RIGHT,
      getConnectedPositionObject(
        'end',
        'center',
        'end',
        'bottom',
        0,
        -20,
        'accredible-tooltip-top-right',
      ),
    ],
  ]);

  constructor(
    private readonly _el: ElementRef,
    private readonly _directionality: Directionality,
    private readonly _overlay: Overlay,
    private readonly _overlayPositionBuilder: OverlayPositionBuilder,
    private readonly _deviceDetector: DeviceDetectorService,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.closeTooltip();
  }

  /**
   * We instantiate the TooltipComponent and we add it to the OverlayRef.
   * Tooltips should not activate on mobile devices.
   */
  @HostListener('mouseenter')
  @HostListener('focus')
  private _showTooltip(): void {
    if (this.tooltipOnlyOnTruncation) {
      if (!isEllipsisActive(this._el)) {
        return;
      }
    }

    if (this._overlayRef?.hasAttached() || this._deviceDetector.isMobile()) {
      return;
    }

    this.tooltipDirection = this._getPositionByDirection(
      this._directionality.value,
      this.tooltipDirection,
    );

    if (!this._tooltipPositions.get(this.tooltipDirection)) {
      this.tooltipDirection = AccredibleTooltipDirection.TOP;
    }

    const position: Partial<ConnectedPosition> = this._tooltipPositions.get(this.tooltipDirection);

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._el)
      .withPositions([
        <ConnectedPosition>position,
        ...this._getRestOfPositions(this.tooltipDirection),
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });

    // Portal allows operating with the PortalOutlet
    const tooltipRef: ComponentRef<AccredibleTooltipComponent> = this._overlayRef.attach(
      new ComponentPortal(AccredibleTooltipComponent),
    );

    tooltipCommonInputs.forEach((input) => {
      (<any>tooltipRef.instance)[input] = (<any>this)[input];
    });
    this.tooltipOpen.emit(true);

    this._listenToOverlayElementMouseEvents();
  }

  /**
   * Tooltips should be closed by mouseleave
   */
  @HostListener('mouseleave')
  @HostListener('focusout')
  private _hideTooltip(): void {
    const mouseMoveListener = (moveEvent: MouseEvent) => {
      this._document.removeEventListener('mousemove', mouseMoveListener);

      if (!this.isMouseInsideTooltipContent(moveEvent)) {
        this.closeTooltip();
      }
    };

    this._document.addEventListener('mousemove', mouseMoveListener);
  }

  private _listenToOverlayElementMouseEvents(): void {
    if (!this._overlayRef?.overlayElement) {
      return;
    }

    const mouseleaveHandler = () => {
      this.closeTooltip();
    };

    this._mouseleaveHandlerOverlay = mouseleaveHandler;

    this._overlayRef.overlayElement.addEventListener('mouseleave', mouseleaveHandler);
  }

  private isMouseInsideTooltipContent(event: MouseEvent): boolean {
    const overlayContainerElement = this._overlayRef?.overlayElement;

    if (!overlayContainerElement) {
      return false;
    }

    return overlayContainerElement.contains(event.target as Node);
  }

  /**
   * Tooltips should be closed by the Escape key
   */
  @HostListener('document:keydown', ['$event'])
  private _hideTooltipByEscape(event: KeyboardEvent): void {
    if (event.key === AccredibleKey.ESCAPE) {
      this.closeTooltip();
    }
  }

  /**
   * If the direction of the document is rtl, it needs to change the position of the top-left
   * and top-right to the opposite
   */
  private _getPositionByDirection(
    directionality: Direction,
    tooltipDirection: AccredibleTooltipDirection,
  ): AccredibleTooltipDirection {
    if (directionality === 'rtl' && tooltipDirection === AccredibleTooltipDirection.TOP_LEFT) {
      return AccredibleTooltipDirection.TOP_RIGHT;
    }

    if (directionality === 'rtl' && tooltipDirection === AccredibleTooltipDirection.TOP_RIGHT) {
      return AccredibleTooltipDirection.TOP_LEFT;
    }

    return this.tooltipDirection;
  }

  /**
   * It should remove from the overlay if there is a tooltip
   */
  closeTooltip(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }

    if (this._mouseleaveHandlerOverlay) {
      this._overlayRef?.overlayElement?.removeEventListener(
        'mouseleave',
        this._mouseleaveHandlerOverlay,
      );
      this._mouseleaveHandlerOverlay = null;
    }

    this.tooltipOpen.emit(false);
  }

  /**
   * Return all the possible tooltip positions except for the selected one.
   */
  private _getRestOfPositions(direction: AccredibleTooltipDirection): ConnectedPosition[] {
    const directions: AccredibleTooltipDirection[] = Object.values(AccredibleTooltipDirection);

    return directions
      .filter((d) => d !== direction)
      .map((d) => <ConnectedPosition>this._tooltipPositions.get(d));
  }
}

const isEllipsisActive = (el: ElementRef): boolean => {
  return el.nativeElement.offsetWidth < el.nativeElement.scrollWidth;
};

/**
 * ConnectedPosition object to define the position of the tooltip in the overlay
 */
const getConnectedPositionObject = (
  originX: 'start' | 'center' | 'end',
  originY: 'top' | 'center' | 'bottom',
  overlayX: 'start' | 'center' | 'end',
  overlayY: 'top' | 'center' | 'bottom',
  offsetX: number,
  offsetY: number,
  panelClass: string,
): Partial<ConnectedPosition> => {
  return {
    originX,
    originY,
    overlayX,
    overlayY,
    offsetX,
    offsetY,
    panelClass,
  };
};
