import { SpotlightProfile } from '../models/spotlight-profiles.model';

export const removeEmptyCustomAttributes = (profile: SpotlightProfile): SpotlightProfile => {
  return {
    ...profile,
    custom_attributes_values: profile.custom_attributes_values.filter(
      (customAttribute) => customAttribute.value !== null,
    ),
  };
};
