import { DefaultFilters, SkillCategory } from '@accredible-frontend-v2/models';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';
import { DirectoryStoreActions } from './directory.actions';

export const directoryFeatureKey = 'directory';

export interface DirectoryState {
  directory: SpotlightDirectory;
  groupsFilterEnabledInSettings: boolean;
  skillCategoryMap: Record<number, SkillCategory>;
  searchByFilters: DefaultFilters;
  loadingUnauthorized: boolean;
  loadingForbidden: boolean;
  privateDirectoryName: string;

  action: DirectoryStateAction;
  payload: any;
  error: any;
}

export enum DirectoryStateAction {
  NO_ACTION,
  DIRECTORY_LOADED,
  ELIGIBLE_GROUPS_LOADED,
  SKILL_CATEGORIES_LOADED,
  HAS_ERROR,
}

const initialStateHandling = <DirectoryState>{
  searchByFilters: {},
  action: DirectoryStateAction.NO_ACTION,
  loadingUnauthorized: false,
  loadingForbidden: false,
  privateDirectoryName: null,
  payload: null,
  error: null,
};

export const initialState: DirectoryState = {
  directory: null,
  ...initialStateHandling,
};

export const reducer = createReducer(
  initialState,

  on(DirectoryStoreActions.loadDirectory, (state) => ({
    ...state,
    ...initialStateHandling,
  })),
  on(DirectoryStoreActions.loadDirectorySuccess, (state, { directory }) => ({
    ...state,
    directory: hideDirectoryFiltersWithNoOptions(directory),
    groupsFilterEnabledInSettings: directory.filters.groups,
    searchByFilters: directory.filters,
    action: DirectoryStateAction.DIRECTORY_LOADED,
  })),
  on(DirectoryStoreActions.loadDirectoryFailure, (state) => ({
    ...state,
    action: DirectoryStateAction.HAS_ERROR,
  })),
  on(DirectoryStoreActions.loadDirectoryUnauthorized, (state, { directoryName }) => ({
    ...state,
    loadingUnauthorized: true,
    privateDirectoryName: directoryName,
  })),
  on(DirectoryStoreActions.loadDirectoryForbidden, (state, { directoryName }) => ({
    ...state,
    loadingForbidden: true,
    privateDirectoryName: directoryName,
  })),
  on(DirectoryStoreActions.loadEligibleGroupsSuccess, (state, { groups }) => ({
    ...state,
    directory: {
      ...state.directory,
      searchable_groups: groups,
      filters:
        groups.length > 0
          ? { ...state.directory.filters, groups: state.groupsFilterEnabledInSettings }
          : { ...state.directory.filters, groups: false },
    },
    action: DirectoryStateAction.ELIGIBLE_GROUPS_LOADED,
  })),
  on(DirectoryStoreActions.loadEligibleGroupsFailure, (state) => ({
    ...state,
    action: DirectoryStateAction.HAS_ERROR,
  })),
  on(DirectoryStoreActions.loadSkillCategoriesSuccess, (state, { categories }) => ({
    ...state,
    directory: {
      ...state.directory,
      categories,
    },
    skillCategoryMap: generateSkillCategoryMap(categories),
    action: DirectoryStateAction.SKILL_CATEGORIES_LOADED,
  })),
  on(DirectoryStoreActions.loadSkillCategoriesFailure, (state) => ({
    ...state,
    action: DirectoryStateAction.HAS_ERROR,
  })),
);

const hideDirectoryFiltersWithNoOptions = (directory: SpotlightDirectory): SpotlightDirectory => {
  const newDirectory = cloneDeep(directory);
  if (directory.searchable_groups.length < 1) {
    // Disable groups filter if there are no groups to filter by
    newDirectory.filters.groups = false;
  }
  if (directory.skills.length < 1) {
    // Disable skills filter if there are no skills to filter by
    newDirectory.filters.skills = false;
  }
  return newDirectory;
};

const generateSkillCategoryMap = (categories: SkillCategory[]): Record<number, SkillCategory> => {
  return Object.fromEntries(categories.map((category) => [category.id, category])) as Record<
    number,
    SkillCategory
  >;
};
