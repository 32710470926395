import { AccredibleChipComponentModule } from '@accredible-frontend-v2/components/chip';
import { AccredibleChipListComponentModule } from '@accredible-frontend-v2/components/chip-list';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/components/icon';
import { AccredibleIcon, AccredibleIconType } from '@accredible-frontend-v2/models';
import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightCredential } from '@spotlight/core/models/spotlight-profiles.model';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AccredibleLocaleDatePipeModule,
    AccredibleChipListComponentModule,
    AccredibleChipComponentModule,
    TranslocoModule,
    MatButtonModule,
    AccredibleIconComponentModule,
  ],
  templateUrl: './certification.component.html',
  styleUrls: [`./certification.component.scss`],
  selector: 'sl-certification',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificationComponent implements OnInit {
  credential = input<SpotlightCredential>();
  skillsLimit = input<number>(1);

  isSkillsLimited = signal(true);
  localCredential: WritableSignal<SpotlightCredential | undefined> = signal(undefined);

  chevronUpIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'icon-chevron-up',
    size: 14,
  };

  warningIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'icon-warning',
    size: 14,
  };

  ngOnInit(): void {
    // We have to filter the learning_outcomes to remove invalid values as sometimes the BE returns an array with null values
    if (this.credential()?.learning_outcomes) {
      this.localCredential.set({
        ...this.credential(),
        learning_outcomes: this.credential().learning_outcomes.filter((skill) => skill),
      });
    } else {
      this.localCredential.set(this.credential());
    }
  }
}
