import { SearchableGroup } from '@accredible-frontend-v2/models';
import { RecipientApiService } from '@accredible-frontend-v2/recipient-api';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';
import { DirectoryStoreActions } from './directory.actions';
import { DirectoryApiService } from './directory.service';

type LoadDirectorySuccessOrFailure = TypedAction<string> &
  ({ directory: SpotlightDirectory } | { error });

@Injectable()
export class DirectoryEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _directoryApi = inject(DirectoryApiService);
  private readonly _recipientApi = inject(RecipientApiService);
  private readonly _router = inject(Router);

  loadDirectory$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DirectoryStoreActions.loadDirectory),
      exhaustMap((action) => {
        return this._directoryApi.loadDirectory(action.domain, action.propagate404).pipe(
          exhaustMap((directory) =>
            this._loadDirectorySkillsAndAvailableGroups(directory, action.organizationIds),
          ),
          catchError((error) => {
            if (error.code === 401) {
              return of(
                DirectoryStoreActions.loadDirectoryUnauthorized({
                  error,
                  directoryName: error.errors?.metadata?.name,
                }),
              );
            } else if (error.code === 403) {
              return of(
                DirectoryStoreActions.loadDirectoryForbidden({
                  error,
                  directoryName: error.errors?.metadata?.name,
                }),
              );
            }
            return of(DirectoryStoreActions.loadDirectoryFailure({ error }));
          }),
        );
      }),
    );
  });

  handleUnauthorized$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadDirectoryUnauthorized),
      tap(() => {
        this._router.navigate(['/unauthorized']);
      }),
      map(({ error }) => DirectoryStoreActions.loadDirectoryFailure({ error })),
    ),
  );

  handleForbidden$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadDirectoryForbidden),
      tap(() => {
        this._router.navigate(['/forbidden']);
      }),
      map(({ error }) => DirectoryStoreActions.loadDirectoryFailure({ error })),
    ),
  );

  loadDirectoryEligibleGroups$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadEligibleGroups),
      switchMap((action) =>
        this._directoryApi.loadDirectoryGroups(action.directoryId, action.organizationIds).pipe(
          map((response: SearchableGroup[]) => {
            return DirectoryStoreActions.loadEligibleGroupsSuccess({ groups: response });
          }),
          catchError((error) => of(DirectoryStoreActions.loadEligibleGroupsFailure({ error }))),
        ),
      ),
    ),
  );

  loadSkillCategories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DirectoryStoreActions.loadSkillCategories),
      exhaustMap(() => this._recipientApi.loadSkillCategories()),
      map((categories) => DirectoryStoreActions.loadSkillCategoriesSuccess({ categories })),
      catchError((error) => of(DirectoryStoreActions.loadSkillCategoriesFailure({ error }))),
    ),
  );

  private _loadDirectorySkillsAndAvailableGroups(
    directory: SpotlightDirectory,
    organizationIds: number[],
  ): Observable<LoadDirectorySuccessOrFailure> {
    const skills$ = this._directoryApi.loadDirectorySkills(directory.id);
    const groups$ = this._directoryApi.loadDirectoryGroups(directory.id, organizationIds);
    const observableArray: Observable<SearchableGroup[] | string[]>[] = [skills$];

    // We need to get the eligible groups if all_eligible_groups_searchable is set to true
    if (directory.all_eligible_groups_searchable) {
      observableArray.push(groups$);
    }

    // TODO(Fred): forkJoin should be used below instead of combineLatest.
    //  We are using combineLatest because for some reason after starting using the featureFlags service inside the _directoryApi,
    //  forkJoin stopped working but combineLatest works.
    //  We can use forkJoin again when feature flag ActiveFeatureFlags.PRIVATE_SPOTLIGHT is removed.
    return combineLatest(observableArray).pipe(
      map(([skills, groups]) => {
        directory.skills = <string[]>skills;
        // If we have a groups$ response replace the searchable_groups value with the groups$ response
        if (groups) {
          // Filter out any invalid groups
          directory.searchable_groups = (<SearchableGroup[]>groups).filter(
            (group) => group.course_name,
          );
        }
        return DirectoryStoreActions.loadDirectorySuccess({ directory });
      }),
      catchError((error) => of(DirectoryStoreActions.loadDirectoryFailure({ error }))),
    );
  }
}
