<div class="tile no-hover"
  *transloco="let t; read: 'sl-work-experience'"
  [class.ghost]="!profile().id">
  <h2 class="section-title">{{ t('work_experience') }}</h2>

  <div *ngFor="let experience of profile().user_work_experiences"
    class="work-experience">
    <div class="trailing-margin-wide">
      <mat-icon class="icon-suitcase"></mat-icon>
    </div>
    
    <div class="details">
      <h3 class="role">{{ experience.role }}</h3>
      <div class="company-name">{{ experience.company_name }}</div>
      <div>{{ experience.start_date | localeDate:'mediumDate': (languageCode$ | async) }}
        - {{
          experience.end_date ? (experience.end_date | localeDate:'mediumDate': (languageCode$ | async)) :
            t('present')
        }}
      </div>
      <div>{{ experience.description }}</div>
    </div>
  </div>
</div>
