import { AccredibleIconComponentModule } from '@accredible-frontend-v2/components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ChipComponent } from './chip.component';

const MATERIAL_MODULES = [MatButtonModule, MatIconModule, MatTooltipModule];

@NgModule({
  imports: [CommonModule, AccredibleIconComponentModule, ...MATERIAL_MODULES],
  exports: [ChipComponent],
  declarations: [ChipComponent],
  providers: [],
})
export class AccredibleChipComponentModule {}
