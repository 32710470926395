import { Component, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { PrivateHeaderComponent } from '@spotlight/ui-common';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectPrivateDirectoryName } from '@spotlight/core/stores/directory/directory.selectors';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    MatIconModule,
    MatLegacyButtonModule,
    AccredibleButtonComponentModule,
    PrivateHeaderComponent,
  ],
  selector: 'spotlight-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  private readonly _document = inject(DOCUMENT);
  private readonly _accountRedirectionService = inject(AccredibleAccountsRedirectionService);
  private readonly _directoryStore = inject(Store<DirectoryState>);

  directoryName$ = this._directoryStore.select(selectPrivateDirectoryName);

  signIn(): void {
    this._document.location.href = this._accountRedirectionService.getLoginUrlOrigin();
  }
}
