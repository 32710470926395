<img *ngIf="img"
  [src]="img"
  alt="">

<accredible-icon *ngIf="!!icon"
  class="icon-img"
  [icon]="icon">
</accredible-icon>

<span class="chip-text font-s-semibold"
  [accredibleTooltip]="chipText"
  [tooltipOnlyOnTruncation]="true">
  {{ chipText }}
</span>

<span *ngIf="isRemovable"
  class="btn-remove"
  role="button"
  [attr.aria-label]="ariaLabelRemove"
  [attr.tabindex]="tabIndexRemove"
  (keydown.enter)="onRemove()"
  (keydown.space)="onRemove()"
  (click)="onRemove()">
  <accredible-icon class="close"
    [icon]="actionIcon">
  </accredible-icon>
</span>
