import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightCredential } from '@spotlight/core/models/spotlight-profiles.model';
import { CertificationComponent } from '../certification/certification.component';

@Component({
  standalone: true,
  imports: [CommonModule, AccredibleLocaleDatePipeModule, CertificationComponent, MatIconModule],
  templateUrl: './timeline.component.html',
  styleUrls: [`./timeline.component.scss`],
  selector: 'sl-timeline',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  private readonly _language = inject(AccredibleLanguageService);
  credentials = input<SpotlightCredential[]>([]);
  isLoading = input(true);

  readonly languageCode$ = this._language.languageCode$;
}
