import { AccredibleChipComponentModule } from '@accredible-frontend-v2/components/chip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipListComponent } from './chip-list.component';

@NgModule({
  imports: [CommonModule, AccredibleChipComponentModule],
  exports: [ChipListComponent],
  declarations: [ChipListComponent],
  providers: [],
})
export class AccredibleChipListComponentModule {}
