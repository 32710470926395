import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'accredible-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: [`./chip-list.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent {
  @Input()
  chips: KeyValue<number, string>[] | string[] = [];
  @Input()
  removable = false;
  @Input()
  chipsAriaLabelKey: string;
  @Input()
  chipsAriaLabelRemoveKey: string;

  @Output()
  remove = new EventEmitter<string | number>();

  constructor(private _language: AccredibleLanguageService) {}

  getAriaLabel(name: string): Observable<string> {
    if (this.chipsAriaLabelKey) {
      return this._language.selectTranslate(this.chipsAriaLabelKey, { name });
    }
    return null;
  }

  getAriaLabelRemove(name: string): Observable<string> {
    if (this.chipsAriaLabelRemoveKey) {
      return this._language.selectTranslate(this.chipsAriaLabelRemoveKey, { name });
    }
    return null;
  }

  onRemove(key: string | number): void {
    this.remove.emit(key);
  }
}
