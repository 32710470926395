import { SkillCategory } from '@accredible-frontend-v2/models';
import { AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RecipientApiService extends AccredibleApiService {
  readonly BASE_URL = '/v1/recipient';
  readonly USERS_URL = '/v1/accounts/users';

  loadSkillCategories(): Observable<SkillCategory[]> {
    return this._get(`${this.BASE_URL}/skill_categories`).pipe(
      map((res) => this._handleResponse(res, 'skill_categories')),
      catchError((res) => this._handleError(res)),
    );
  }

  setRecoveryEmail(email: string, password: string): Observable<boolean> {
    return this._post(`${this.BASE_URL}/user_emails/set_recovery_email`, { email, password }).pipe(
      map((res: { success: boolean }) => this._handleResponse(res, 'success')),
      catchError((err) => this._handleError(err)),
    );
  }

  resendEmailVerification(userId: number, email: string): Observable<boolean> {
    return this._post(`${this.USERS_URL}/${userId}/user_emails/resend_verification_email`, {
      email,
    }).pipe(
      map((res: { success: boolean }) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  cancelEmailVerification(userId: number): Observable<boolean> {
    return this._put(`${this.USERS_URL}/${userId}/user_emails/cancel_verification_email`, {}).pipe(
      map((res: { success: boolean }) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }
}
