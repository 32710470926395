import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightProfileDetailed } from '@spotlight/core/models/spotlight-profiles.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightApiService } from '@spotlight/shared/services/sl-api/sl-api.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProfileApiService extends SpotlightApiService {
  loadProfile(directoryId: number, id: number): Observable<SpotlightProfileDetailed> {
    const url = `/spotlight/v1/spotlight_directories/${directoryId}/users/${id}`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'user')),
      catchError((res) => this._handleError(res, true)),
    );
  }

  trackCopyProfileLink(directoryId: number, userId: string): Observable<void> {
    const url = `/spotlight/v1/spotlight_directories/${directoryId}/spotlight_directory_analytics/copy_profile_link_button_clicked`;

    return this._post(url, { ident: userId }).pipe(map((res) => this._handleResponse(res)));
  }
}
