@if (skills().length) {
  <div class="skills"
    *transloco="let t; read: 'sl-credentials-skills'">
    <div class="skills-header">
      {{ t('skills') }}
    </div>
    <ul>
      @for (skill of skills().slice(0, limitVisibleSkills() ? skills().length : skillsLimit); track skill) {
        <li>
          <accredible-chip [data]="skill"/>
        </li>
      }

      @if (skills().length > skillsLimit) {
        <li class="skill-toggle">
          <accredible-chip [icon]="limitVisibleSkills() ? chevronUpIcon : null"
            [data]="limitVisibleSkills() ? t('show_less') : '+ ' + (skills().length - skillsLimit)"
            (click)="limitVisibleSkills.set(!limitVisibleSkills())"/>
        </li>
      }
    </ul>
  </div>
}
