import { AccredibleAvatarComponentModule } from '@accredible-frontend-v2/components/avatar';
import { AccredibleChipListComponentModule } from '@accredible-frontend-v2/components/chip-list';
import { AccredibleContentFadeDirectiveModule } from '@accredible-frontend-v2/directives/content-fade';
import { AccredibleChipComponentModule } from '@accredible-frontend-v2/new-components/chip';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UserCustomAttributesComponentModule } from '@spotlight/core/components/user-custom-attributes/user-custom-attributes.module';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightDirectory } from '@spotlight/core/models/spotlight-directory.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightProfileDetailed } from '@spotlight/core/models/spotlight-profiles.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SocialLinksComponentModule } from '@spotlight/shared/social-links/social-links.component.module';
import { CredentialsSkillsComponent } from '../credentials-skills/sl-credentials-skills.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SocialLinksComponentModule,
    UserCustomAttributesComponentModule,
    AccredibleAvatarComponentModule,
    AccredibleChipListComponentModule,
    AccredibleLocaleDatePipeModule,
    AccredibleContentFadeDirectiveModule,
    TranslocoModule,
    MatButtonModule,
    AccredibleIconComponentModule,
    AccredibleChipComponentModule,
    CredentialsSkillsComponent
  ],
  templateUrl: './user-details.component.html',
  styleUrls: [`./user-details.component.scss`],
  selector: 'sl-user-details',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent {
  private readonly _language = inject(AccredibleLanguageService);

  directory = input<SpotlightDirectory>();
  profile = input.required<SpotlightProfileDetailed>();

  trackEvent = output<string>();

  userBioExpanded = signal(false);
  showBioExpandButton = signal(false);
  showSkillsAndMore = signal(false);

  @ViewChild('profileBio')
  profileBio: ElementRef<HTMLElement>;

  readonly languageCode$ = this._language.languageCode$;
  bioCollapseBreakpoint = 160;

  credentialsSkills = computed(() => {
    const profile = this.profile();
    if (!profile) return [];

    const allCredentials = [
      ...(profile.directory_credentials ?? []),
      ...(profile.non_directory_credentials ?? []),
    ];

    const uniqueSkills = [
      ...new Set(allCredentials.flatMap((credential) => credential?.learning_outcomes ?? [])),
    ].filter(Boolean);

    return uniqueSkills.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  });

  constructor() {
    effect(() => {
      if (this.profile()?.id) {
        setTimeout(() => {
          this.showBioExpandButton.set(
            this.profileBio?.nativeElement.scrollHeight > this.bioCollapseBreakpoint,
          );
        }, 500);
      }
    });
  }

  toggleBio(): void {
    this.userBioExpanded.update((value) => !value);
  }

  toggleSkills(): void {
    this.showSkillsAndMore.update((value) => !value);
  }
}
