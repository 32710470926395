import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoDirective, AccredibleIconComponentModule],
  selector: 'spotlight-private-header',
  templateUrl: './private-header.component.html',
  styleUrls: ['./private-header.component.scss'],
})
export class PrivateHeaderComponent {
  directoryName = input<string>();
}
