import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { UserCustomAttributesComponent } from './user-custom-attributes.component';

const MATERIAL_MODULES = [MatButtonModule, MatIconModule];

@NgModule({
  declarations: [UserCustomAttributesComponent],
  imports: [CommonModule, ...MATERIAL_MODULES],
  exports: [UserCustomAttributesComponent],
})
export class UserCustomAttributesComponentModule {}
