@if (tooltipContent || customTemplate) {
  <div class="accredible-tooltip-wrapper"
    [style.max-width]="tooltipMaxWidth + 'px'"
    [class.custom-template]="customTemplate"
    [class.accredible-rich-tooltip]="richTooltipTitle">

    @if (richTooltipTitle) {
      <div class="title font-xs-bold">{{ richTooltipTitle }}</div>
      <div class="font-xs-regular">{{ tooltipContent }}</div>

      @if (richTooltipHref || richTooltipEmbeddingRoute || richTooltipEmbeddingRouterLink) {
        <a class="font-s-semibold"
          accredible-link
          target="_blank"
          [href]="richTooltipHref"
          [size]="'small'"
          [icon]="'arrow-right'"
          [invertIconRtl]="true">
          {{ richTooltipLinkText }}
        </a>
      } @else if (richTooltipEmbeddingRoute || richTooltipEmbeddingRouterLink) {
        <!-- TODO V1(Fred): Change embedding directive logic with routerLink once we get rid of V1 -->
        <a class="font-s-semibold"
          accredible-link
          [size]="'small'"
          [icon]="'arrow-right'"
          [invertIconRtl]="true"
          [embeddingRoute]="richTooltipEmbeddingRoute"
          [embeddingRouteParams]="richTooltipEmbeddingRouteParams"
          [embeddingRouterLink]="richTooltipEmbeddingRouterLink"
          [embeddingHrefUrl]="richTooltipEmbeddingHrefUrl">
          {{ richTooltipLinkText }}
        </a>
      }
    } @else {
      @if (!tooltipEscapeHTML) {
        {{ tooltipContent }}
      } @else {
        <div [innerHTML]="tooltipContent"
          [ngClass]="tooltipCustomClass">
        </div>
      }
    }

    @if (customTemplate) {
      <ng-container [ngTemplateOutlet]="customTemplate"/>
    }
  </div>
}
