import { EmbeddingRouterLinkDirectiveModule } from '@accredible-frontend-v2/embedding';
import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleTooltipComponent } from './tooltip.component';
import { AccredibleTooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [AccredibleTooltipDirective, AccredibleTooltipComponent],
  imports: [
    CommonModule,
    OverlayModule,
    EmbeddingRouterLinkDirectiveModule,
    AccredibleLinkComponentModule,
  ],
  exports: [AccredibleTooltipDirective, AccredibleTooltipComponent],
})
export class AccredibleTooltipModule {}
