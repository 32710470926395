import { ActiveFeatureFlags, SearchableGroup } from '@accredible-frontend-v2/models';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpotlightApiService } from '../../../shared/services/sl-api/sl-api.service';
import { SpotlightDirectory } from '../../models/spotlight-directory.model';

export const DIRECTORIES_BASE_URL = '/spotlight/v1/spotlight_directories';
export const OLD_DIRECTORIES_BASE_URL = '/v1/credential-net/spotlight_directories';

@Injectable({ providedIn: 'root' })
export class DirectoryApiService extends SpotlightApiService {
  private readonly _features = inject(AccredibleRecipientFeatureFlagsService);

  loadDirectory(domain: string, propagate404: boolean): Observable<SpotlightDirectory> {
    return this._features.getFeatureFlags().pipe(
      switchMap((flags) => {
        const url = flags[ActiveFeatureFlags.PRIVATE_SPOTLIGHT]
          ? `${DIRECTORIES_BASE_URL}/by_domain?domain=${domain}`
          : `${OLD_DIRECTORIES_BASE_URL}/by_domain?domain=${domain}`;
        return this._get(url).pipe(
          map((res) => this._handleResponse(res, 'spotlight_directory')),
          catchError((res) => this._handleError(res, propagate404)),
        );
      }),
    );
  }

  loadDirectorySkills(directoryId: number): Observable<string[]> {
    return this._features.getFeatureFlags().pipe(
      switchMap((flags) => {
        const url = flags[ActiveFeatureFlags.PRIVATE_SPOTLIGHT]
          ? `${DIRECTORIES_BASE_URL}/${directoryId}/skills`
          : `${OLD_DIRECTORIES_BASE_URL}/${directoryId}/skills`;
        return this._get(url).pipe(
          map((res) => this._handleResponse(res, 'skills')),
          catchError((res) => this._handleError(res)),
        );
      }),
    );
  }

  loadDirectoryGroups(
    directoryId: number,
    organizationIds?: number[],
  ): Observable<SearchableGroup[]> {
    let queryParam = '';
    if (Array.isArray(organizationIds) && organizationIds.length > 0) {
      queryParam = `?organization_ids[]=${organizationIds
        .map((id) => id)
        .join('&organization_ids[]=')}`;
    }

    return this._features.getFeatureFlags().pipe(
      switchMap((flags) => {
        const url = flags[ActiveFeatureFlags.PRIVATE_SPOTLIGHT]
          ? `${DIRECTORIES_BASE_URL}/${directoryId}/eligible_groups${queryParam}`
          : `${OLD_DIRECTORIES_BASE_URL}/${directoryId}/eligible_groups${queryParam}`;
        return this._get(url).pipe(
          map((res) => this._handleResponse(res, 'groups')),
          catchError((res) => this._handleError(res)),
        );
      }),
    );
  }
}
