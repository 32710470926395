<div class="user-custom-attributes">
  <ng-container *ngFor="let attribute of customProfileAttributes">
    <div class="user-custom-attribute"
      *ngIf="!(customAttributeType.BOOLEAN &&  !attribute.value)"
      [ngSwitch]="attribute.type">
      <span class="attribute-label">{{ attribute.label }}:</span>

      <div class="attribute-value select-any-value-container"
        *ngSwitchCase="customAttributeType.SELECT_ANY">
        <span *ngFor="let item of attribute.value.slice()">{{ item }}</span>
      </div>

      <div class="attribute-value"
        *ngSwitchCase="customAttributeType.BOOLEAN">
        <mat-icon>check</mat-icon>
      </div>

      <div class="attribute-value"
        *ngSwitchDefault>
        {{ attribute.value }}
      </div>
    </div>
  </ng-container>
</div>
