import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { Injectable } from '@angular/core';

@Injectable()
export class AccredibleBaseContainer {
  constructor(protected readonly _seo: AccredibleSeoService) {}

  protected _updateMetaTags(optionalParameter?: unknown): void {
    this._seo.generateTags();
  }
}
