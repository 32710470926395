import { AccredibleSocialMedia } from '@accredible-frontend-v2/models';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';

@Component({
  selector: 'sl-social-links',
  templateUrl: 'social-links.component.html',
  styleUrls: ['social-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLinksComponent {
  private readonly _languageService = inject(AccredibleLanguageService);
  name = input<string>();
  socialLinks = input<AccredibleSocialMedia>();
  ariaLabel = input<string>();
  showAdditionalLinks = input<boolean>(false);
  socialNetworkClicked = output<string>();

  twitterLink = computed(() => {
    if (!this.socialLinks()?.twitter) return '';

    const twitterUsername = <string>this.socialLinks()?.twitter;
    return (
      'https://twitter.com/' +
      twitterUsername
        // Removing the @ symbol if exists
        .replace(/^@/, '')
        .replace(/^(https?:\/\/)?(www\.)?twitter\.com\//, '')
    );
  });

  additionalSocialLinksChips = computed(() => {
    if (!this.showAdditionalLinks() || !this.socialLinks()) {
      return [];
    }

    const excludedSocialTypes = ['linkedin', 'twitter'];
    const socialMediaList = [];

    for (const [provider, value] of Object.entries(this.socialLinks())) {
      if (excludedSocialTypes.includes(provider)) {
        continue;
      }

      if (provider === 'other') {
        socialMediaList.push({
          value: this._languageService.translate('social-links.other'),
          url: this._ensureProtocol(value.url),
        });
      } else {
        socialMediaList.push({
          value: this._languageService.translate(`social-links.${provider}`),
          url: this._ensureProtocol(value),
        });
      }
    }

    return socialMediaList;
  });

  private _ensureProtocol(url: string): string {
    if (!url) return url;
    return url.match(/^https?:\/\//) ? url : `https://${url}`;
  }
}
