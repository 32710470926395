import { ActiveFeatureFlags } from '@accredible-frontend-v2/models';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SpotlightApiService } from '../sl-api/sl-api.service';

@Injectable({
  providedIn: 'root',
})
export class SpotlightEventTrackingService extends SpotlightApiService {
  private readonly _features = inject(AccredibleRecipientFeatureFlagsService);

  trackEvent(directoryId: number, from: string, social: string): Observable<any> {
    const oldUrl = `/v1/recipient/analytics/spotlight_directories/${directoryId}/social_button_clicked`;
    const url = `/spotlight/v1/spotlight_directories/${directoryId}/spotlight_directory_analytics/social_button_clicked`;

    return this._features.getFeatureFlags().pipe(
      switchMap((flags) => {
        const flaggedUrl = flags[ActiveFeatureFlags.PRIVATE_SPOTLIGHT] ? url : oldUrl;
        return this._post(flaggedUrl, {
          from,
          social,
        }).pipe(
          map((res) => this._handleResponse(res)),
          catchError((res) => this._handleError(res, true)),
        );
      }),
    );
  }
}
