import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SpotlightProfileDetailed } from '@spotlight/core/models/spotlight-profiles.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DirectoryState } from '@spotlight/core/stores/directory/directory.reducer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { selectDirectory } from '@spotlight/core/stores/directory/directory.selectors';
import { TimelineComponent } from '../timeline/timeline.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TimelineComponent,
    TranslocoModule,
    MatIconModule,
    AccredibleIconComponentModule,
  ],
  templateUrl: './certifications-list.component.html',
  styleUrls: [`./certifications-list.component.scss`],
  selector: 'sl-certifications-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificationsListComponent {
  private readonly _directoryStore = inject(Store<DirectoryState>);

  profile = input<SpotlightProfileDetailed>();
  isLoading = input(true);

  directory$ = this._directoryStore.select(selectDirectory);

  walletURL = computed(() => {
    return `${environment.credentialNetUrl}/profile/${this.profile().username}/wallet`;
  });
}
