<div *transloco="let t; read: 'unauthorized'"
  class="unauthorized-container">
  <spotlight-private-header [directoryName]="directoryName$ | async" />

  <img src="assets/images/hints-and-guidance/lock.svg"
    [alt]="">

  <h1 class="mat-h1">{{ t('title') }}</h1>

  <div>{{ t('subtitle') }}</div>

  <button mat-raised-button
    color="accent"
    (keydown.space)="signIn()"
    (keydown.enter)="signIn()"
    (click)="signIn()">
    {{ t('sign_in') }}
  </button>

  <p>{{ t('hint') }}</p>
</div>