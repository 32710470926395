import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleChipComponent } from './chip.component';

@NgModule({
  imports: [CommonModule, AccredibleIconComponentModule, AccredibleTooltipModule],
  declarations: [AccredibleChipComponent],
  exports: [AccredibleChipComponent],
})
export class AccredibleChipComponentModule {}
