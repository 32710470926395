<div *transloco="let t; read: 'forbidden'"
  class="forbidden-container">
  <spotlight-private-header [directoryName]="directoryName$ | async" />

  <img src="assets/images/hints-and-guidance/error3.svg"
    [alt]="">

  <h1 class="mat-h1">{{ t('title') }}</h1>

  <div>
    <div>{{ t('subtitle_1') }}</div>
    <div>{{ t('subtitle_2') }}</div>
  </div>
</div>