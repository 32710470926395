import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconComponent } from './icon.component';

const MATERIAL_MODULES = [MatIconModule];

@NgModule({
  imports: [CommonModule, ...MATERIAL_MODULES],
  exports: [IconComponent],
  declarations: [IconComponent],
  providers: [],
})
export class AccredibleIconComponentModule {}
