import { CustomAttributeType } from '@accredible-frontend-v2/models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomProfileValue } from '../../models/spotlight-profiles.model';

@Component({
  selector: 'sl-user-custom-attributes',
  templateUrl: './user-custom-attributes.component.html',
  styleUrls: ['./user-custom-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCustomAttributesComponent {
  @Input()
  customProfileAttributes: CustomProfileValue[];

  readonly customAttributeType = CustomAttributeType;
}
