import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { removeEmptyCustomAttributes } from '@spotlight/core/helpers/profile-processing.helper';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  SpotlightCredential,
  SpotlightProfileDetailed,
} from '@spotlight/core/models/spotlight-profiles.model';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { ProfileApiService } from './profile.service';

export interface ProfileState {
  profile: SpotlightProfileDetailed;

  action: ProfileStateAction;
  payload: any;
  error: any;
}

export enum ProfileStateAction {
  NO_ACTION,

  PROFILE_LOADED,

  TRACK_LINK_COPIED,

  HAS_ERROR,
}

const initialStateHandling: Partial<ProfileState> = {
  action: ProfileStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState: ProfileState = {
  profile: {
    id: null,
    location: {},
    skills: ['', '', ''],
    availability: ['', '', ''],
    directory_credentials: [
      { issuer: { learning_outcomes: [] } },
      { issuer: { learning_outcomes: [] } },
      { issuer: { learning_outcomes: [] } },
    ],
    non_directory_credentials: [],
    user_work_experiences: [],
  },
  ...(<ProfileState>initialStateHandling),
};

@Injectable()
export class ProfileStore extends ComponentStore<ProfileState> {
  readonly loadProfile = this.effect((action$: Observable<{ directoryId: number; id: number }>) => {
    return action$.pipe(
      exhaustMap((action) =>
        this._profileApi.loadProfile(action.directoryId, action.id).pipe(
          tapResponse(
            (profile) => {
              this.setState((state) => ({
                ...state,
                profile: removeEmptyCustomAttributes(profile),
                ...(<ProfileState>initialStateHandling),
                action: ProfileStateAction.PROFILE_LOADED,
              }));
            },
            (error) => this._handleError(error),
          ),
        ),
      ),
    );
  });

  readonly trackCopyProfileLink = this.effect(
    (action$: Observable<{ directoryId: number; userId: string }>) => {
      return action$.pipe(
        exhaustMap((action) =>
          this._profileApi.trackCopyProfileLink(action.directoryId, action.userId).pipe(
            tapResponse(
              () => {
                this.patchState({
                  action: ProfileStateAction.TRACK_LINK_COPIED,
                });
              },
              (error) => this._handleError(error),
            ),
          ),
        ),
      );
    },
  );

  readonly profile$ = this.select((state) => {
    const profile = cloneDeep(state.profile);

    // We have to filter the skills to remove invalid values as sometimes the BE returns an array with null values
    if (profile.skills) {
      profile.skills = profile.skills.filter((skills) => skills);
    }

    // We have to filter the learning_outcomes to remove invalid values as sometimes the BE returns an array with null values
    profile.directory_credentials = profile.directory_credentials?.map((credential) => {
      const output = <SpotlightCredential>{ ...credential };
      if (credential.learning_outcomes) {
        output.learning_outcomes = credential.learning_outcomes?.filter(
          (learningOutcome) => learningOutcome,
        );
      }
      return output;
    });

    return profile;
  });

  constructor(private readonly _profileApi: ProfileApiService) {
    super(initialState);
  }

  private _handleError(
    error: any,
    action: ProfileStateAction = ProfileStateAction.HAS_ERROR,
  ): Observable<never> {
    this.setState((state) => ({
      ...state,
      action,
    }));

    throw error;
  }
}
