import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'accredible-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleChipComponent {
  @Input()
  icon: string;
  @Input()
  img: string;
  @Input()
  size: 'normal' | 'small' = 'normal';
  @Input()
  ariaLabelRemove: string;
  @Input()
  tabIndexRemove = 0;
  @Input()
  color: 'natural' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger' = 'natural';
  @Input()
  maxWidth: string;
  @Input()
  actionIcon: 'close-small' | 'plus' = 'close-small';

  @Output()
  remove = new EventEmitter<KeyValue<number, string> | string>();

  chipText: string;

  @HostBinding('class')
  get classes(): string {
    const ghostClass = !this.chipText && !this.icon ? 'ghost' : '';
    const removableClass = this.isRemovable ? 'removable' : '';
    return [ghostClass, removableClass, this.color, this.size].join(' ');
  }

  @HostBinding('style')
  get style(): string {
    return this.maxWidth ? `max-width: ${this.maxWidth}` : '';
  }

  get isRemovable(): boolean {
    return this.remove.observed;
  }

  @Input()
  set data(data: KeyValue<number, string> | string) {
    this.chipText = typeof data === 'object' ? data.value : data;
  }

  onRemove(): void {
    this.remove.emit(<KeyValue<number, string> | string>this.chipText);
  }
}
