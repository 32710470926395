@if (localCredential()) {
  <div class="credential-wrapper"
    *transloco="let t; read: 'sl-certification'"
    [class.ghost]="!localCredential().title">

    <div class="credential-right">

      <div class="credential trailing-margin-wide">
        <img [src]="localCredential().thumbnail"
          [attr.alt]="t('alt_image', { credential: localCredential().title })">
        @if (localCredential()?.expired) {
          <div class="expired">
            <accredible-chip [icon]="warningIcon"
              color="danger"
              [data]="t('expired')"/>
          </div>
        }
      </div>

      <div class="details">
        <div class="details-top-content">
          <a class="title"
            target="_blank"
            [attr.aria-label]="t('aria_credential', { credential: localCredential().title })"
            [href]="localCredential().url">
            {{ localCredential().title }}
          </a>

          @if (localCredential()?.endorsements?.length) {
            <accredible-chip class="endorsement-chip"
              img="assets/images/colored-icons/endorsement.svg"
              [data]="localCredential()?.endorsements.length > 1 ? t('endorsements', {count: localCredential()?.endorsements.length}) : t('endorsed_by', {endorser: localCredential()?.endorsements[0]?.endorser_name}) "/>
          }
        </div>

        <div class="issuer">
          @if (localCredential().issuer.logo) {
            <div class="logo">
              <img [src]="localCredential().issuer.logo"
                aria-hidden="true"
                alt="Issuer logo">
            </div>
          }

          <a target="_blank"
            [attr.aria-label]="t('aria_issuer', { issuer: localCredential().issuer.name })"
            [href]="localCredential().issuer.url">
            {{ localCredential().issuer.name }}
          </a>
        </div>

        @if (localCredential().learning_outcomes?.length > skillsLimit()) {
          <div class="skills">
            <ul>
              @for (skill of localCredential().learning_outcomes.slice(0,
                isSkillsLimited() ? skillsLimit() : localCredential().learning_outcomes.length); track skill) {
                <li>
                  <accredible-chip [data]="skill"/>
                </li>
              }

              <li class="skill-toggle">
                <accredible-chip [icon]="isSkillsLimited() ? null : chevronUpIcon"
                  [data]="isSkillsLimited()
                ? '+ ' + (localCredential().learning_outcomes.length - skillsLimit())
                : t('less_skills')"
                  (click)="isSkillsLimited.set(!isSkillsLimited())"/>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  </div>
}
