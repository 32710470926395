import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StateChange } from 'ng-lazyload-image';

let nextUniqueId = 0;

@Component({
  selector: 'accredible-avatar, accredible-avatar[default-image-header]',
  templateUrl: './avatar.component.html',
  styleUrls: [`./avatar.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input()
  isLoading = true;
  @Input()
  withBorder = true;
  @Input()
  imageUrl: string;
  @Input()
  size = '40px'; // To be used as width and height of the avatar
  @Input()
  shape: 'circle' | 'square' = 'circle';
  @Input()
  defaultBackground: string;

  initials: string;
  initialsBgColor: string;

  // a11y: Colors set below meet required contrast ratios https://webaim.org/resources/contrastchecker/
  initialsTextColor = '#ffffff';
  allowedColors = [
    '#6472B9',
    '#4200FF',
    '#B15703',
    '#227B66',
    '#8E56C7',
    '#DC00AC',
    '#3F8409',
    '#6368DD',
    '#B11500',
    '#8D6862',
  ];

  private _name: string;

  get name(): string {
    return this._name;
  }

  @Input()
  set name(name: string) {
    if (name) {
      this._name = name;
      this.initials = _nameToInitials(this._name);
      this.initialsBgColor = this._setupColors();
    }
  }

  lazyLoadOnStatechange(event: StateChange): void {
    switch (event.reason) {
      case 'loading-failed':
        this.imageUrl = null;
        break;
    }
  }

  private _setupColors(): string {
    nextUniqueId++;
    return this.allowedColors[nextUniqueId % this.allowedColors.length];
  }
}

const _nameToInitials = (name: string): string => {
  let initials = '';

  const names = name.trim().split(/\s+/gi);
  names.forEach((name) => {
    initials += name[0].toLocaleUpperCase() || '';
  });

  return initials.substring(0, 2); // Max of 2 char
};
