<div [matTooltip]="chipTooltip"
  [attr.tabindex]="chipTooltip ? 0 : null"
  [attr.role]="role || null"
  [attr.aria-label]="ariaLabel || null">
  <accredible-icon *ngIf="!!icon"
    class="trailing-margin-narrow"
    [icon]="icon">
  </accredible-icon>
  @if (!!img()) {
    <img [src]="img()"
      alt=""/>
  }

  <span [class.trailing-margin-narrow]="removable">
    {{ removable ? value : data }}
  </span>

  <button *ngIf="removable"
    class="btn-remove"
    mat-icon-button
    type="button"
    [attr.aria-label]="ariaLabelRemove"
    [attr.tabindex]="tabIndexRemove"
    (click)="onRemove()">
    <mat-icon>close</mat-icon>
  </button>
</div>
