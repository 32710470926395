<ul>
  <li *ngFor="let chip of chips">
    <accredible-chip [data]="chip"
      [removable]="removable"
      [ariaLabel]="getAriaLabel(chip?.value || chip) | async"
      [ariaLabelRemove]="getAriaLabelRemove(chip?.value || chip) | async"
      (remove)="onRemove($event)">
    </accredible-chip>
  </li>
</ul>

