import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  createNgModule,
  DestroyRef,
  inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, first, take } from 'rxjs/operators';
import { ThemeHelper } from '../themes/theme.helper';
import { DirectoryStoreActions } from './stores/directory/directory.actions';
import { DirectoryState, DirectoryStateAction } from './stores/directory/directory.reducer';
import { selectDirectoryStateAction } from './stores/directory/directory.selectors';

const DEFAULT_THEME_DOMAIN = 'default.spotlight.theme';

enum BrowserStorageKey {
  THEME_DOMAIN = 'themeDomain',
}

enum ComponentType {
  HEADER = 'header',
  FOOTER = 'footer',
  BANNER = 'banner',
}

@Component({
  selector: 'sl-root',
  templateUrl: './spotlight.container.html',
  styleUrls: ['./spotlight.container.scss'],
})
export class SpotlightContainer implements OnInit, AfterViewInit {
  private readonly _document = inject(DOCUMENT);
  private readonly _injector = inject(Injector);
  private readonly _browserStorage = inject(AccredibleBrowserStorageService);
  private readonly _directoryStore = inject(Store<DirectoryState>);
  private readonly _seoService = inject(AccredibleSeoService);
  private readonly _userApi = inject(AccredibleUserApiService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _destroyRef = inject(DestroyRef);

  @ViewChild('headerContainer', { read: ViewContainerRef })
  headerContainer: ViewContainerRef;
  @ViewChild('footerContainer', { read: ViewContainerRef })
  footerContainer: ViewContainerRef;
  @ViewChild('bannerContainer', { read: ViewContainerRef })
  bannerContainer: ViewContainerRef;

  customBanner = true;
  // This is only used inside the Theme Generator iframe
  showIframeNavigation = false;
  isInThemeGeneratorPreviewIframe = false;
  initialOrganizationIds: number[];

  readonly version = environment.version;

  private _theme = ThemeHelper.getTheme();

  ngOnInit(): void {
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((routerEvent: Event | RouterEvent) => routerEvent instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        const { organizations } = this._route.snapshot.queryParams;
        if (organizations) {
          this.initialOrganizationIds = Array.isArray(organizations)
            ? organizations.map(Number)
            : [Number(organizations)];
        }
        // We don't propagate 404 on the first try if the request fails and we are theming, this allows for the employment.accredible.com directory to be loaded as a fallback
        this._directoryStore.dispatch(
          DirectoryStoreActions.loadDirectory({
            domain: this.getSpotlightDomain(),
            propagate404: !environment.theming,
            organizationIds: this.initialOrganizationIds,
          }),
        );
      });

    // if we are theming, listen to the directory store, if the themeDomain gives us a 404 try again with employment.accredible.com
    if (environment.theming) {
      this._setupDirectoryFailureFallback();
    }

    this._seoService.generateTags();

    this.showIframeNavigation = WindowHelper.isInIframe();
  }

  ngAfterViewInit(): void {
    if (environment.theming && this._browserStorage.get(BrowserStorageKey.THEME_DOMAIN)) {
      this._theme = ThemeHelper.getTheme(this._browserStorage.get(BrowserStorageKey.THEME_DOMAIN));
    }

    this._loadHeaderComponent().then();
    this._loadFooterComponent().then();
    this._loadBannerComponent().then();
    this._setupOnLogout();
  }

  private _setupDirectoryFailureFallback(): void {
    // We attempt to load the employment.accredible.com directory, if this fails we then propagate 404
    this._directoryStore
      .select(selectDirectoryStateAction)
      .pipe(first((action) => action === DirectoryStateAction.HAS_ERROR))
      .subscribe(() => {
        setTimeout(() => {
          this._directoryStore.dispatch(
            DirectoryStoreActions.loadDirectory({
              domain: `${environment.prefix}employment.accredible.com`,
              propagate404: true,
              organizationIds: this.initialOrganizationIds,
            }),
          );
        }, 500);
      });
  }

  private getSpotlightDomain(): string {
    const domain = this._document.domain;

    // If theming we return the theme's domain if it is in the browser otherwise employment.accredible.com
    if (environment.theming) {
      return this._browserStorage.get('themeDomain') || 'employment.accredible.com';
    }
    // If we're serving locally and not theming we return the environments employment.accredible.com
    if (domain.includes('localhost')) {
      return `${environment.prefix}employment.accredible.com`;
    }
    // Default behaviour is to just return the domain
    return domain;
  }

  private async _loadHeaderComponent(): Promise<void> {
    // If this theme is a duplicate, reference the domain of the original theme in order to get the header component
    const themeDomain = accredibleCustomThemesMetadata[this._theme]?.duplicateOf || this._theme;

    // We use a try catch here so the app will attempt to load the header component for this._theme but if it does not exist, e.g. if it's not been built. We load the default header component instead.
    try {
      const { HeaderComponent } = await import(`../themes/${themeDomain}/header/header.component`);
      import(`../themes/${themeDomain}/header/header.component.module`)
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.HEADER, module, HeaderComponent);
          if (this.isInThemeGeneratorPreviewIframe && themeDomain !== DEFAULT_THEME_DOMAIN) {
            _emitCustomHeaderFooterStatus({ customHeader: true });
          }
        });
    } catch {
      const { HeaderComponent } = await import(
        '../themes/default.spotlight.theme/header/header.component'
      );
      import('../themes/default.spotlight.theme/header/header.component.module')
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.HEADER, module, HeaderComponent);
          if (this.isInThemeGeneratorPreviewIframe) {
            _emitCustomHeaderFooterStatus({ customHeader: false });
          }
        });
    }
  }

  private async _loadFooterComponent(): Promise<void> {
    // If this theme is a duplicate, reference the domain of the original theme in order to get the footer component
    const themeDomain = accredibleCustomThemesMetadata[this._theme]?.duplicateOf || this._theme;

    // We use a try catch here so the app will attempt to load the footer component for this._theme but if it does not exist, e.g. it's not been built. We load default footer component instead.
    try {
      const { FooterComponent } = await import(`../themes/${themeDomain}/footer/footer.component`);
      import('../themes/' + themeDomain + '/footer/footer.component.module')
        .then((m) => m.FooterComponentModule)
        .then(async (module: unknown) => {
          await this._createComponent(ComponentType.FOOTER, module, FooterComponent);
          if (this.isInThemeGeneratorPreviewIframe && themeDomain !== DEFAULT_THEME_DOMAIN) {
            _emitCustomHeaderFooterStatus({ customFooter: true });
          }
        });
    } catch {
      const { FooterComponent } = await import(
        '../themes/default.spotlight.theme/footer/footer.component'
      );
      import('../themes/default.spotlight.theme/footer/footer.component.module')
        .then((m) => m.FooterComponentModule)
        .then(async (module: unknown) => {
          await this._createComponent(ComponentType.FOOTER, module, FooterComponent);
          if (this.isInThemeGeneratorPreviewIframe) {
            _emitCustomHeaderFooterStatus({ customFooter: false });
          }
        });
    }
  }

  private async _loadBannerComponent(): Promise<void> {
    // If this theme is a duplicate, reference the domain of the original theme in order to get the banner component
    const themeDomain = accredibleCustomThemesMetadata[this._theme]?.duplicateOf || this._theme;

    try {
      // We attempt to load a banner and if one is available it will be loaded, if not no banner will be shown
      const { BannerComponent } = await import(`../themes/${themeDomain}/banner/banner.component`);
      import(`../themes/${themeDomain}/banner/banner.component.module`)
        .then((m) => m.BannerComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.BANNER, module, BannerComponent);
        });
    } catch {
      // No custom banner is available for this theme remove the container from the DOM
      this.customBanner = false;
    }
  }

  private async _createComponent(
    componentType: ComponentType,
    module: any,
    component: any,
  ): Promise<void> {
    const moduleRef = createNgModule(module, this._injector);

    switch (componentType) {
      case ComponentType.HEADER:
        this.headerContainer.clear();
        this.headerContainer.createComponent(component, { ngModuleRef: moduleRef });
        break;
      case ComponentType.FOOTER:
        this.footerContainer.clear();
        this.footerContainer.createComponent(component, { ngModuleRef: moduleRef });
        break;
      case ComponentType.BANNER:
        this.bannerContainer.clear();
        this.bannerContainer.createComponent(component, { ngModuleRef: moduleRef });
        break;
    }
  }

  private _setupOnLogout(): void {
    this._userApi.onLogout$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((loggedOut) => {
      // On logout, refresh the page
      if (loggedOut) {
        window.location.reload();
      }
    });
  }
}

const _emitCustomHeaderFooterStatus = (componentStatus: object): void => {
  window.parent.postMessage(componentStatus, '*');
};
