@if (socialLinks()) {
  <nav role="navigation"
    class="social-links">
    <div class="social-links-container">
      <ul *transloco="let t; read: 'social-links'"
        [attr.aria-label]="ariaLabel()">
        @if (socialLinks().linkedin) {
          <li (click)="socialNetworkClicked.emit('linkedin')">
            <a [href]="socialLinks().linkedin"
              [attr.aria-label]="t('linkedin', { name: name })"
              class="linkedin"
              target="_blank">
              <mat-icon class="icon-linkedin1"></mat-icon>
            </a>
          </li>
        }

        @if (socialLinks().twitter) {
          <li>
            <a [href]="twitterLink()"
              [attr.aria-label]="t('x', { name: name })"
              (click)="socialNetworkClicked.emit('twitter')"
              class="twitter"
              target="_blank">
              <accredible-icon icon="x"/>
            </a>
          </li>
        }
      </ul>

      @if (showAdditionalLinks()) {
        <div class="additional-social-links-container">
          @for (chip of additionalSocialLinksChips(); track chip.value) {
            <a [href]="chip.url"
              target="_blank">
              <accredible-chip [data]="chip.value"/>
            </a>
          }
        </div>
      }
    </div>
  </nav>
}
